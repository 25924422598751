






















import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { api as configApi } from "@/store/modules/configuration";
import { api } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import { proxyEndpointUrl } from "@/utils/HttpApi";

@Component({
  components: {},
})
export default class Settings extends Vue {
  @Action(api.actions.setBreadCrumb, { namespace: api.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(api.actions.setMenuPanel, { namespace: api.namespace })
  setMenuPanel!: TodoActionSignature;

  authenticationInterval: ReturnType<typeof setInterval> | null = null;
  grafanaFrameUrl: string | null = null;

  @Getter(configApi.getters.getManagementSummaryAllowedRoles, {
    namespace: configApi.namespace,
  })
  managementSummaryAllowedRoles!: string[];

  get hasAccess() {
    return this.managementSummaryAllowedRoles.some(role =>
      this.$user.roles().includes(role)
    );
  }

  get isSysAdmin() {
    return this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR);
  }

  get configRoute() {
    return {
      name: ROUTE_NAMES.CONFIGURATION,
      params: {
        segmentName: "app",
      },
    };
  }
  get userManagementRoute() {
    return { name: ROUTE_NAMES.USER_MANAGEMENT_USERS };
  }

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.management_summary.breadcrumb"),
        to: { name: ROUTE_NAMES.MANAGEMENT_SUMMARY },
        activeclass: "",
      },
    ];
  }

  async authenticateWithProxy() {
    const firebaseUser = window.TheFirebase?.auth().currentUser;
    if (firebaseUser == null) {
      return null;
    }
    const idToken = await firebaseUser.getIdToken();
    const authUrl = `${proxyEndpointUrl("auth")}?token=${idToken}`;
    this.grafanaFrameUrl = authUrl;
  }

  created() {
    this.authenticateWithProxy();

    // every 10 seconds, check if the ID token must be refreshed
    this.authenticationInterval = setInterval(
      () => this.authenticateWithProxy(),
      10 * 1000
    );
  }

  destroyed() {
    if (this.authenticationInterval) {
      clearInterval(this.authenticationInterval);
    }
  }

  mounted() {
    this.setMenuPanel("breadcrumb");
    this.setBreadCrumb(this.currentBreadCrumb);
  }
}
